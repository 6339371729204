import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {
  LANGUAGE_STORAGE_KEY,
  LanguageEnum,
  LanguageIsoEnum,
  RouteToLanguageEnum,
  LanguageToRouteEnum
} from '@app/dictionary/models/dictionary.model';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DefaultLanguageGuard  {
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    let localStorage = this.document.defaultView?.localStorage;
    const paramLang = route.params['language'];

    if (!localStorage) {
      return true;
    }

    if (RouteToLanguageEnum[paramLang]) {
      return false;
    }

    const lang = localStorage?.getItem(LANGUAGE_STORAGE_KEY);
    if (LanguageIsoEnum[lang] && lang !== LanguageEnum.English) {
      const targetUrl = state.url.includes('?')
        ? state.url.split('?')[0]
        : state.url;

      this.router.navigate([`${LanguageToRouteEnum[lang]}${targetUrl}`], {
        queryParams: route.queryParams
      });
      return false;
    }

    return true;
  }
}
