export class JoinUsJobHashTagFilterModel {
  id: number;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  updatedBy: any;
  countJob: number;
  jobPost: JobPost[];

  static fromJSON(data: any): JoinUsJobHashTagFilterModel {
    return {
      id: data.id,
      name: data.name,
      slug: data.slug,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      createdBy: data.created_by,
      updatedBy: data.updated_by,
      countJob: data.count_job,
      jobPost: data.job_post.map((item) => ({
        id: item.id,
        short_desc_am: item.short_desc_am,
        short_desc_ru: item.short_desc_ru,
        short_desc_en: item.short_desc_en,
        description_am: item.description_am,
        description_ru: item.description_ru,
        description_en: item.description_en,
        link: item.link,
        slug: item.slug,
        createdBy: item.created_by,
        updatedBy: item.updated_by,
        isPublic: item.is_public,
        disableDate: item.disable_date,
        disablePeriod: item.disable_period,
        createdAt: item.created_at,
        updatedAt: item.updated_at,
        requirements_am: item.requirements_am,
        requirements_ru: item.requirements_ru,
        requirements_en: item.requirements_en,
        experience_am: item.experience_am,
        experience_ru: item.experience_ru,
        experience_en: item.experience_en,
        experienceLevelId: item.experience_level_id,
        titleId: item.title_id,
        pivot: {
          jobPostHashTagId: item.job_post_hash_tag_id,
          jobPostId: item.job_post_id,
        },
      })),
    };
  }
}

export interface JobPost {
  id: number;
  short_desc_am: string;
  short_desc_ru: any;
  short_desc_en: string;
  description_am: string;
  description_ru: any;
  description_en: string;
  link: string;
  slug: string;
  createdBy: number;
  updatedBy: any;
  isPublic: number;
  disableDate: string;
  disablePeriod: any;
  createdAt: string;
  updatedAt: string;
  requirements_am: string;
  requirements_ru: any;
  requirements_en: string;
  experience_am: string;
  experience_ru: any;
  experience_en: string;
  experienceLevelId: number;
  titleId: number;
  pivot: Pivot;
}

export interface Pivot {
  jobPostHashTagId: number;
  jobPostId: number;
}
