import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  input,
  output,
  PLATFORM_ID
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { COOKIE_KEY } from '@app/shared/models/local-storage.model';

@Component({
  selector: 'app-cookie-pop-up',
  standalone: true,
  imports: [TranslateModule, RouterModule],
  templateUrl: './cookie-pop-up.component.html',
  styleUrls: ['./cookie-pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePopUpComponent {
  cookieSeen = output();
  isCookieHidden = input.required();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  onHideCookie() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(COOKIE_KEY, 'true');
      this.cookieSeen.emit();
    }
  }
}
