import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { DictionaryModel } from '@app/dictionary/models/dictionary.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {
  private readonly baseUrl = `${environment.apiUrl}/dictionary`;

  constructor(private http: HttpClient) {}

  getDictionaries(): Observable<DictionaryModel[]> {
    return this.http
      .get(this.baseUrl)
      .pipe(
        map((res: any) =>
          res.message.map((dictionary: any) =>
            DictionaryModel.fromJson(dictionary)
          )
        )
      );
  }
}
