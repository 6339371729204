import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Loader } from '@googlemaps/js-api-loader';
import { GoogleMapService } from '@app/shared/services/google-map.service';
import { HeaderService } from '@app/shared/services/header.service';
import { LanguageService } from '@app/dictionary/language.service';
import { HeaderComponent } from '@app/core/layout/header/header.component';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '@app/core/layout/footer/footer.component';

@Component({
  standalone: true,
  imports: [CommonModule, HeaderComponent, RouterOutlet, FooterComponent],
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private googleMapService: GoogleMapService,
    public headerService: HeaderService,
    public languageService: LanguageService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const loader = new Loader({
        apiKey: 'AIzaSyCIAKYa_NJKpNNccReXrQX8EVnJWQgOGgk',
        version: 'weekly',
        libraries: ['places']
      });

      loader.importLibrary('core').then(async () => {
        this.googleMapService.isMapsLoaded$.next(true);
      });
    }
  }
}
