import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class GtagService {
  gtag(...arg: any[]) {
    if (environment.production && typeof gtag !== 'undefined') {
      gtag(...arg);
    } else {
      console.log(
        `%c${arg.join(', ')}`,
        'color: #e83c12; font-size:18px; font-weight: bold;'
      );
    }
  }
}
