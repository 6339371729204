import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';
import { JoinUsJobPostsModel } from '@app/_api/models/join-us-job-posts.model';
import { JoinUsJobHashTagFilterModel } from '@app/_api/models/join-us-job-hash-tag-filter.model';
import {
  JoinUsSendCvReqModel,
  JoinUsUploadCvFileModel
} from '@app/_api/models/join-us-upload-cv.model';

@Injectable({
  providedIn: 'root'
})
export class JoinUsService {
  private readonly baseUrl = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  getJoinUsJobs(): Observable<JoinUsJobPostsModel[]> {
    return this.http
      .get<JoinUsJobPostsModel[]>(`${this.baseUrl}/job-posts`)
      .pipe(
        map((res: any) =>
          res.response.map((data: JoinUsJobPostsModel[]) =>
            JoinUsJobPostsModel.fromJSON(data)
          )
        )
      );
  }

  getJobHashTagFilter(): Observable<JoinUsJobHashTagFilterModel[]> {
    return this.http
      .get<JoinUsJobHashTagFilterModel[]>(`${this.baseUrl}/job-hash-tag-filter`)
      .pipe(
        map((res: any) =>
          res.response.map((data: any) =>
            JoinUsJobHashTagFilterModel.fromJSON(data)
          )
        )
      );
  }

  getJobPost(id: number): Observable<JoinUsJobPostsModel> {
    return this.http
      .get<JoinUsJobPostsModel>(`${this.baseUrl}/job-post/${id}`)
      .pipe(map((res: any) => JoinUsJobPostsModel.fromJSON(res.response)));
  }

  uploadCvFile(body: any): Observable<JoinUsUploadCvFileModel> {
    return this.http.post<JoinUsUploadCvFileModel>(
      `${this.baseUrl}/upload-cv-file`,
      body
    );
  }

  sendCv(body: JoinUsSendCvReqModel): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/upload-cv`, body)
      .pipe(map((res) => res));
  }
}
