import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl
} from '@angular/platform-browser';
import { DomSanitizerEnum } from '@app/shared/models/dom-sanitizer.model';

@Pipe({ name: 'safe', standalone: true })
export class SanitizePipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(
    value: string,
    sanitizerType = DomSanitizerEnum.SafeHtml
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl | string {
    switch (sanitizerType) {
      case DomSanitizerEnum.SafeHtml:
        return this._sanitizer.bypassSecurityTrustHtml(value);
      case DomSanitizerEnum.SafeStyle:
        return this._sanitizer.bypassSecurityTrustStyle(value);
      case DomSanitizerEnum.SafeScript:
        return this._sanitizer.bypassSecurityTrustScript(value);
      case DomSanitizerEnum.SafeUrl:
        return this._sanitizer.bypassSecurityTrustUrl(value);
      case DomSanitizerEnum.SafeResourceUrl:
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
    }
  }
}
