export class OurTeamModel {
  id: number;
  name_am: string;
  name_ru: string;
  name_en: string;
  surname_am: string;
  surname_ru: string;
  surname_en: string;
  email: string;
  isPublic: number;
  positionId: number;
  image?: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  position: Position;
  hashTags: HashTag[];

  static fromJSON(data: any): OurTeamModel {
    return {
      id: data.id,
      name_am: data.name_am,
      name_ru: data.name_ru,
      name_en: data.name_en,
      surname_am: data.surname_am,
      surname_ru: data.surname_ru,
      surname_en: data.surname_en,
      email: data.email,
      isPublic: data.is_public,
      positionId: data.position_id,
      image: data.image,
      slug: data.slug,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      position: {
        id: data.position.id,
        name: data.position.name,
        slug: data.position.slug,
        createdAt: data.position.created_at,
        updatedAt: data.position.updated_at,
        createdBy: data.position.created_by,
        updatedBy: data.position.updated_by
      },
      hashTags: data.hash_tags.map((elem) => ({
        id: elem.id,
        name: elem.name,
        slug: elem.slug,
        createdAt: elem.created_at,
        updatedAt: elem.updated_at,
        createdBy: elem.created_by,
        updatedBy: elem.updated_by,
        pivot: {
          teamId: elem.pivot.team_id,
          hashTagId: elem.pivot.hash_tag_id
        }
      }))
    };
  }
}

export interface Position {
  id: number;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  updatedBy: number;
}

export interface HashTag {
  id: number;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  updatedBy: number;
  pivot: Pivot;
}

export interface Pivot {
  teamId: number;
  hashTagId: number;
}
