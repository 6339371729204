import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SeoConfig } from '@app/shared/models/seo.model';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, take } from 'rxjs';
import { getBaseDomain } from '@app/shared/utiles/urls';
import {
  LanguageEnum,
  LanguageToRouteEnum
} from '@app/dictionary/models/dictionary.model';
import { environment } from '@env/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  numberOfEmployees: number;
  numberOfEmployeesLoaded$: ReplaySubject<boolean> = new ReplaySubject<boolean>(
    1
  );

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private document: Document
  ) {}

  configSeo(config: SeoConfig) {
    this.updateTitle(config.title);
    this.updateMetaTags(config);
  }

  updateTitle(title: string) {
    this.titleService.setTitle(title);
  }

  updateMetaTags(config: SeoConfig) {
    // Dynamic meta tags
    this.metaService.updateTag({
      name: 'description',
      content: config.description
    });
    this.metaService.updateTag({ name: 'language', content: config.language });

    // Static meta tags
    this.metaService.updateTag({ name: 'google', content: 'notranslate' });
    this.metaService.updateTag({ name: 'author', content: 'Sceon' });
    this.metaService.updateTag({ name: 'copyright', content: 'Sceon' });

    // Open Graph
    this.metaService.updateTag({ property: 'og:title', content: config.title });
    this.metaService.updateTag({
      property: 'og:url',
      content: config.currentPageUrl
    });
    this.metaService.updateTag({
      property: 'og:site_name',
      content: 'Sceon'
    });
    this.metaService.updateTag({
      property: 'og:description',
      content: config.description
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: config.ogImageOrTwitterImage
    });
    this.metaService.updateTag({ property: 'og:type', content: 'website' });
    this.metaService.updateTag({
      property: 'article:author',
      content: 'https://www.facebook.com/sceondev'
    });
    // this.metaService.updateTag({
    //   property: 'fb:app_id',
    //   content: '405420562171302'
    // });

    // Twitter
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
    this.metaService.updateTag({
      name: 'twitter:site',
      content: '@YourTwitterHandle'
    });
    this.metaService.updateTag({
      name: 'twitter:title',
      content: config.title
    });
    this.metaService.updateTag({
      name: 'twitter:image',
      content: config.ogImageOrTwitterImage
    });
    this.metaService.updateTag({
      name: 'twitter:description',
      content: config.description
    });

    // Update link tags dynamically - This part requires direct DOM manipulation
    this.updateLinkTags(config);
  }

  private updateLinkTags(config: SeoConfig) {
    let defaultCurrentPageUrl = config.currentPageUrl;
    let currentPageUrlHy = config.currentPageUrl;

    if (this.translateService.currentLang !== LanguageEnum.English) {
      defaultCurrentPageUrl = config.currentPageUrl.replace(
        /^(https?:\/\/[^\/]+)\/\p{L}{2}(\/|$)/u,
        '$1/'
      );
    } else {
      const parts = config.currentPageUrl.split('/');
      parts.splice(3, 0, LanguageToRouteEnum.am);
      currentPageUrlHy = parts.join('/');
    }

    this.updateLinkTag('home', environment.baseDomain);
    this.updateLinkTag('canonical', config.currentPageUrl);
    this.updateLinkTag('alternate', defaultCurrentPageUrl, 'x-default');
    this.updateLinkTag(
      'alternate',
      defaultCurrentPageUrl,
      LanguageEnum.English
    );
    this.updateLinkTag('alternate', currentPageUrlHy, LanguageToRouteEnum.am);
  }

  private updateLinkTag(rel: string, href: string, hreflang?: string) {
    const query = hreflang
      ? `link[rel="${rel}"][hreflang="${hreflang}"]`
      : `link[rel="${rel}"]`;
    let link: HTMLLinkElement = this.document.querySelector(query);
    if (!link) {
      link = this.document.createElement('link');
      link.setAttribute('rel', rel);
      this.document.head.appendChild(link);
    }
    link.setAttribute('href', href);
    if (hreflang) {
      link.setAttribute('hreflang', hreflang);
    }
  }

  updateSceonGlobalSchema = ({
    description,
    title,
    currentPageUrl,
    breadcrumbList
  }: {
    description: string;
    title: string;
    currentPageUrl: string;
    breadcrumbList: { title: string; pageUrl: string }[];
  }) => {
    let existingScript: HTMLScriptElement;
    let fbImage: string;

    fbImage =
      'https://www.facebook.com/photo/?fbid=557793019481333&set=a.557792999481335';

    existingScript = this.document.querySelector(
      'script[type="application/ld+json"]'
    );

    if (existingScript) {
      this.translateService
        .getTranslation(this.translateService.currentLang)
        .pipe(take(1))
        .subscribe((translations) => {
          // If the script exists, update its content
          const newText = JSON.stringify({
            '@context': 'https://schema.org',
            '@graph': [
              {
                '@type': 'Organization',
                '@id': `${getBaseDomain()}/#organization`,
                url: `${getBaseDomain()}/`,
                name: 'Sceon',
                legalName: 'Sceondev LLC',
                slogan: translations['seo_slogan'],
                address: {
                  '@type': 'PostalAddress',
                  streetAddress: 'A. Armenakyan 2/4',
                  addressLocality: 'Yerevan',
                  addressRegion: 'Yerevan',
                  postalCode: '0047',
                  addressCountry: 'Armenia'
                },
                email: 'info@sceon.am',
                telephone: '+37412522922',
                foundingDate: '2012',
                // founders: [],
                numberOfEmployees: {
                  '@type': 'QuantitativeValue',
                  value: this.numberOfEmployees
                },
                contactPoint: [
                  {
                    '@type': 'ContactPoint',
                    telephone: '+37412522922',
                    email: 'info@sceon.am',
                    contactType: 'customer service'
                  }
                ],
                sameAs: [
                  'https://www.facebook.com/sceondev/',
                  'https://www.linkedin.com/company/sceon/',
                  'https://www.instagram.com/sceondev/'
                ],
                logo: {
                  '@type': 'ImageObject',
                  '@id': `${getBaseDomain()}/#logo`,
                  url: `${getBaseDomain()}/assets/images/seo/sceon-logo.webp`,
                  width: 180,
                  height: 41,
                  caption: 'Sceon'
                },
                image: {
                  '@type': 'ImageObject',
                  url: fbImage,
                  width: 1280,
                  height: 720
                }
                // review: []
              },
              {
                '@type': 'WebSite',
                '@id': `${getBaseDomain()}/#website`,
                url: getBaseDomain(),
                name: title,
                description: description,
                publisher: {
                  '@id': `${getBaseDomain()}/#organization`
                },
                copyrightHolder: {
                  '@id': `${getBaseDomain()}/#organization`
                }
              },
              {
                '@type': 'WebPage',
                '@id': `${getBaseDomain()}/#webpage`, //here we need to have existing page url after url /#webpage
                url: currentPageUrl, //here we need to have existing page url
                inLanguage:
                  LanguageToRouteEnum[this.translateService.currentLang],
                isPartOf: {
                  '@id': `${getBaseDomain()}/#website`
                },
                about: {
                  '@id': `${getBaseDomain()}/#organization`
                },
                description: description,
                primaryImageOfPage: {
                  '@id': `${fbImage}/#post-image`
                },
                headline: title,
                datePublished: '2022-06-01T03:45:34-04:00',
                dateModified: '2022-06-01T03:45:34-04:00'
              },
              {
                '@type': 'ImageObject',
                '@id': `${fbImage}/#post-image`,
                url: fbImage,
                contentUrl: fbImage,
                width: 1000,
                height: 1000
              },
              {
                '@type': 'BreadcrumbList',
                '@id': `${getBaseDomain()}/#breadcrumb`,
                itemListElement: [
                  {
                    '@type': 'ListItem',
                    position: 1,
                    name: title,
                    item: `${getBaseDomain()}`
                  },
                  ...breadcrumbList.map((item, index) => ({
                    '@type': 'ListItem',
                    position: index + 2,
                    name: item.title,
                    item: item.pageUrl
                  }))
                ]
              }
              // { // This part for Blog
              //   '@type': 'Article',
              //   '@id': 'Paste here article page url /#article',
              //   headline: 'Paste here page Title',
              //   mainEntityOfPage: 'Paste here article page url /#webpage',
              //   isPartOf: { '@id': 'Paste here article page url /#webpage' },
              //   datePublished: '2022-06-01T03:45:34-04:00',
              //   dateModified: '2022-06-01T03:45:34-04:00',
              //   url: 'Paste here article page url ',
              //   image: { '@id': 'Past here page share image url /#post-image' },
              //   publisher: { '@id': `${getBaseDomain()}/#organization` },
              //   description: 'Paste here page meta description',
              //   thumbnailUrl: 'Past here page share image url',
              //   copyrightYear: '2022',
              //   copyrightHolder: { '@id': `${getBaseDomain()}getBaseDomain/#organization` },
              //   wordCount: 854
              // }
            ]
          });
          existingScript.textContent = newText;
        });
    } else {
      // If the script does not exist, you can choose to add it or handle as needed
      console.warn('Script tag not found, consider adding it.');
    }
  };
}
