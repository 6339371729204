import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { RouteToLanguageEnum } from '@app/dictionary/models/dictionary.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageGuard  {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const lang = route.params['language'];

    if (RouteToLanguageEnum[lang]) {
      return true;
    }

    this.router.navigate(['404']);
    return false;
  }
}
