import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { TeamHashTagFilterModel } from '@app/_api/models/team-hash-tag-filter.model';
import { OurTeamModel } from '@app/_api/models/our-team.model';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  private readonly baseUrl = `${environment.apiUrl}`;

  constructor(private httpClient: HttpClient) {}

  getJobHashTagFilter(): Observable<TeamHashTagFilterModel[]> {
    return this.httpClient
      .get<TeamHashTagFilterModel[]>(`${this.baseUrl}/get-hash-tag-filter`)
      .pipe(
        map((res: any) =>
          res.response.map((data: TeamHashTagFilterModel[]) =>
            TeamHashTagFilterModel.fromJSON(data)
          )
        )
      );
  }

  getOurTeam(): Observable<OurTeamModel[]> {
    return this.httpClient
      .get<OurTeamModel[]>(`${this.baseUrl}/our-team`)
      .pipe(
        map((res: any) =>
          res.response.map((data: OurTeamModel[]) =>
            OurTeamModel.fromJSON(data)
          )
        )
      );
  }
}
