export const getCurrentPureUrl = () => {
  if (typeof window !== 'undefined') {
    const currentUrl = window.location.href;
    return currentUrl.includes('?') ? currentUrl.split('?')[0] : currentUrl;
  }

  return '';
};

export const getBaseDomain = (): string => {
  if (typeof window !== 'undefined') {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
  }

  return '';
};

export const getSeoImageUrl = (imgName: string): string => {
  return `${getBaseDomain()}/assets/images/seo/${imgName}`;
};
