import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';

import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import {
  LANGUAGE_STORAGE_KEY,
  LanguageEnum,
  LanguageIsoEnum,
  LanguageToRouteEnum
} from '@app/dictionary/models/dictionary.model';
import { generateFacebookUrl } from '@app/shared/utiles/social-media';
import { DomSanitizerEnum } from '@app/shared/models/dom-sanitizer.model';
import { SanitizePipe } from '@app/shared/pipe/safe.pipe';
import { LanguageService } from '@app/dictionary/language.service';
import { JoinUsService } from '@app/_api/join-us.service';
import { JoinUsJobPostsModel } from '@app/_api/models/join-us-job-posts.model';
import { BlogModel } from '@app/_api/models/blog.model';
import { GtagService } from '@app/shared/services/gtag.service';

@Component({
  standalone: true,
  selector: 'app-header',
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    TranslateModule,
    MatButtonModule,
    SanitizePipe
  ],
  providers: [TranslateService],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @ViewChild('menuElement', { static: true }) menuElement: ElementRef;
  @Input() set selectedLanguage(lan: string) {
    this.languageCounter = LanguageIsoEnum[lan];
  }

  openMenu = false;
  languageCounter: LanguageIsoEnum | number;
  languageViewObj = { '1': LanguageEnum.English, '2': LanguageEnum.Armenian };
  facebookUrl: string;
  domSanitizerEnum = DomSanitizerEnum;
  hotVacanciesList: JoinUsJobPostsModel[] = [];
  lastBlogData: BlogModel | null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public router: Router,
    public translate: TranslateService,
    public languageService: LanguageService,
    private joinUsService: JoinUsService,
    // private blogService: BlogService,
    private changeDetectorRef: ChangeDetectorRef,
    private gtagService: GtagService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    this.languageCounter = LanguageIsoEnum[this.translate.currentLang];
    if (isPlatformBrowser(this.platformId)) {
      this.facebookUrl = generateFacebookUrl();
    }

    this.joinUsService
      .getJoinUsJobs()
      .subscribe((data: JoinUsJobPostsModel[]) => {
        this.hotVacanciesList = data;
      });

    // this.blogService.getLastBlog().subscribe((data: BlogModel | null) => {
    // this.lastBlogData = data;
    // this.lastBlogData = null;
    // });
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.openMenu) {
      this.hideMenu();
    }
  }

  navigateHome() {
    this.gtagService.gtag('header_home');
    this.router.navigate(['']);
    this.hideMenu();
  }

  gaEvent(eventName: string) {
    this.gtagService.gtag(`header_${eventName}`);
  }

  changeLang() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.languageCounter === Object.keys(LanguageEnum).length) {
      this.languageCounter = 0;
    }

    this.languageCounter++;
    const newLang = this.languageViewObj[this.languageCounter];
    localStorage.setItem(LANGUAGE_STORAGE_KEY, newLang);
    let targetUrl: string;
    if (newLang === LanguageEnum.English) {
      targetUrl = '/' + this.router.url.split('/').slice(2).join('/');
    } else {
      targetUrl = `${LanguageToRouteEnum[newLang]}${this.router.url}`;
    }

    window.location.href = targetUrl;
  }

  toggleMenu() {
    this.gtagService.gtag(`header_hamburger_click`);
    this.openMenu = !this.openMenu;

    if (this.openMenu) {
      this.document.body.classList.add('open');
      setTimeout(() => {
        this.listenOutsideClick();
      });
    } else {
      this.document.body.classList.remove('open');
      this.removeOutsideClickListener();
    }
  }

  hideMenu() {
    this.openMenu = false;
    this.document.body.classList.remove('open');
    this.changeDetectorRef.detectChanges();
    this.removeOutsideClickListener();
  }

  listenOutsideClick = () => {
    this.document.addEventListener('click', this.onOutsideClick);
  };

  removeOutsideClickListener = () => {
    this.document.removeEventListener('click', this.onOutsideClick);
  };

  onOutsideClick = (event: MouseEvent) => {
    if (!this.menuElement.nativeElement.contains(event.target as Node)) {
      this.hideMenu();
    }
  };

  get queryWhatWeDoParams() {
    return {
      focus: 'true'
    };
  }
}
