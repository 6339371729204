@if (!isCookieHidden()) {
  <div class="cookie-body">
    <img src="assets/images/cookie.svg" alt="cookie" />
    <p class="cookie-text">
      {{ 'cookiePopUp_textPart1' | translate }}
      <a routerLink="cookie-policy"
        >{{ 'cookiePopUp_textPart2' | translate }}
      </a>
    </p>
    <button class="close-btn" (click)="onHideCookie()">
      <span class="icon-cancel-icon"></span>
    </button>
  </div>
}
