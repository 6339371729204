import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import {
  of,
  Subject,
  tap,
  delay,
  fromEvent,
  mergeMap,
  startWith,
  takeUntil
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  take$: Subject<boolean> = new Subject();
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document
  ) {}

  listen() {
    if (isPlatformBrowser(this.platformId)) {
      return of(true).pipe(
        delay(0),
        mergeMap(() => {
          return fromEvent(this.document, 'scroll').pipe(
            startWith(1),
            tap(() => {
              this.toggleClass();
            }),
            takeUntil(this.take$)
          );
        })
      );
    } else {
      return of();
    }
  }

  toggleClass() {
    if (isPlatformBrowser(this.platformId)) {
      let collection: NodeListOf<HTMLElement> | null =
        this.document.querySelectorAll('._dark');

      let change = Array.from(collection).find((item) => {
        return (
          window.scrollY >= item.offsetTop &&
          window.scrollY <= item.offsetTop + item.getBoundingClientRect().height
        );
      });

      if (change) {
        this.setDarkMode();
      } else {
        this.setWhiteMode();
      }
    }
  }

  setWhiteMode() {
    this.document.body.classList.remove('dark-body');
    this.document.body.classList.add('white-body');
  }

  setDarkMode() {
    this.document.body.classList.add('dark-body');
    this.document.body.classList.remove('white-body');
  }

  removeListener() {
    this.take$.next(false);
    this.take$.complete();
  }
}
