export class JoinUsJobPostsModel {
  id: number;
  short_desc_am: string;
  short_desc_ru: any;
  short_desc_en: string;
  description_am: string;
  description_ru: any;
  description_en: string;
  link: string;
  slug: string;
  createdBy: number;
  updatedBy: any;
  isPublic: number;
  disableDate: Date;
  disablePeriod: any;
  createdAt: string;
  updatedAt: string;
  jobRequirements: Requirements[];
  jobExperiences: Experiences[];
  experienceLevelId: number;
  titleId: number;
  title: Title;
  experienceLevel: ExperienceLevel;
  hashTags: HashTag[];

  static fromJSON(data: any): JoinUsJobPostsModel {
    return {
      id: data.id,
      short_desc_am: data.short_desc_am,
      short_desc_ru: data.short_desc_ru,
      short_desc_en: data.short_desc_en,
      description_am: data.description_am,
      description_ru: data.description_ru,
      description_en: data.description_en,
      link: data.link,
      slug: data.slug,
      createdBy: data.created_by,
      updatedBy: data.updated_by,
      isPublic: data.is_public,
      disableDate: data.disable_date ? new Date(data.disable_date) : null,
      disablePeriod: data.disable_period,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      jobExperiences: data.job_experiences.map((experience) => ({
        id: experience.id,
        experience_en: experience.experience_en,
        experience_am: experience.experience_am,
        experience_ru: experience.experience_ru,
        createdAt: experience.created_at,
        updatedAt: experience.updated_at,
        pivot: {
          jobPostId: experience.pivot.job_post_id,
          jobPostJobPostsExperienceId:
            experience.pivot.job_post_job_posts_experience_id
        }
      })),
      jobRequirements: data.job_requirements.map((requirement) => ({
        id: requirement.id,
        requirements_en: requirement.requirements_en,
        requirements_am: requirement.requirements_am,
        requirements_ru: requirement.requirements_ru,
        createdAt: requirement.created_at,
        updatedAt: requirement.updated_at,
        pivot: {
          jobPostId: requirement.pivot.job_post_id,
          jobPostJobPostsRequirementId:
            requirement.job_post_job_posts_requirement_id
        }
      })),
      experienceLevelId: data.experience_level_id,
      titleId: data.title_id,
      title: {
        id: data.title.id,
        title_am: data.title.title_am,
        title_en: data.title.title_en,
        slug: data.title.slug,
        createdBy: data.title.created_by,
        updatedBy: data.title.updated_by,
        createdAt: data.title.created_at,
        updatedAt: data.title.updated_at
      },
      experienceLevel: {
        id: data.experience_level.id,
        title_am: data.experience_level.title_am,
        title_ru: data.experience_level.title_ru,
        title_en: data.experience_level.title_en,
        slug: data.experience_level.slug,
        createdBy: data.experience_level.created_by,
        updatedBy: data.experience_level.updated_at,
        createdAt: data.experience_level.created_at,
        updatedAt: data.experience_level.updated_at
      },
      hashTags: data.hash_tags.map((item) => ({
        id: item.id,
        name: item.name,
        slug: item.slug,
        createdAt: item.created_at,
        updatedAt: item.updated_at,
        createdBy: item.created_by,
        updatedBy: item.updated_by,
        pivot: {
          jobPostId: item.pivot.job_post_id,
          jobPostHashTagId: item.job_post_hash_tag_id
        }
      }))
    };
  }
}

export interface Title {
  id: number;
  title_am: string;
  title_en: string;
  slug: string;
  createdBy: number;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
}

export interface ExperienceLevel {
  id: number;
  title_am: string;
  title_ru: string;
  title_en: string;
  slug: string;
  createdBy: number;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
}

export interface HashTag {
  id: number;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  updatedBy: any;
  pivot: Pivot;
}

export interface Pivot {
  jobPostId: number;
  jobPostHashTagId?: number;
  jobPostJobPostsRequirementId?: number;
  jobPostJobPostsExperienceId?: number;
}

export interface Experiences {
  id: number;
  experience_en: string;
  experience_am: string;
  experience_ru: any;
  createdAt: string;
  updatedAt: string;
  pivot: Pivot;
}

export interface Requirements {
  id: number;
  requirements_en: string;
  requirements_am: string;
  requirements_ru: any;
  createdAt: string;
  updatedAt: string;
  pivot: Pivot;
}
