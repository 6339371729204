export const generateFacebookUrl = () => {
  let facebookUrl = 'https://www.facebook.com/sceondev';
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1;
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  if (isAndroid) {
    facebookUrl = 'fb://page/100800797969020';
  } else if (isIOS) {
    facebookUrl = 'fb://profile/100800797969020';
  }

  return facebookUrl;
};
