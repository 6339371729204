<footer class="footer-lang-{{ translateService.currentLang }}">
  <div class="container sm footer-lg">
    <div class="logo-items">
      <div class="logo">
        <a [routerLink]="['']" >
          <img src="assets/images/sceon-logo.svg" alt="sceon-logo" />
        </a>
      </div>

      <div class="nav-items">
        <a
          (click)="gaEvent('home')"
          [routerLink]="['/']"
          aria-label="Visit home page"
          class="hover-grow"
          >{{ 'navigation_home' | translate }}</a
        >
        <a
          (click)="gaEvent('what-we-do')"
          [routerLink]="['what-we-do']"
          aria-label="Visit what we do page"
          class="hover-grow"
          >{{ 'navigation_whatWeDo' | translate }}</a
        >
        <a
          (click)="gaEvent('who-we-are')"
          [routerLink]="['who-we-are']"
          aria-label="Visit who we are page"
          class="hover-grow"
          >{{ 'navigation_whoWeAre' | translate }}</a
        >
        <a
          (click)="gaEvent('team')"
          [routerLink]="['team']"
          aria-label="Visit team page"
          class="hover-grow"
          >{{ 'navigation_team' | translate }}</a
        >
        <a
          (click)="gaEvent('join-us')"
          [routerLink]="['join-us']"
          aria-label="Visit join us page"
          class="hover-grow"
          >{{ 'navigation_joinUs' | translate }}</a
        >
        <!--        <a-->
        <!--          (click)="gaEvent('blog')"-->
        <!--          [routerLink]="['blog']"-->
        <!--          aria-label="Visit blog page"-->
        <!--          class="hover-grow"-->
        <!--          >{{ 'navigation_blog' | translate }}</a-->
        <!--        >-->
        <a
          (click)="gaEvent('contact-us')"
          [routerLink]="['contact-us']"

          class="hover-grow"
          >{{ 'navigation_contactUs' | translate }}</a
        >
      </div>
    </div>

    <div class="social-info">
      <div class="info">
        <a
          (click)="gaEvent('email')"
          href="mailto:{{ 'navigation_email' | translate }}"
          aria-label="open emil"
          ><i class="icon-email-icon"></i>
          <span [innerHTML]="'navigation_email' | translate"></span></a
        >
        <a
          (click)="gaEvent('phone')"
          href="tel:{{ 'navigation_phone' | translate }}"
          aria-label="Telephone number"
          ><i class="icon-phone-icon"></i>
          <span [innerHTML]="'navigation_phone' | translate"></span></a
        >
        <a
          (click)="gaEvent('location')"
          target="_blank"
          href="https://www.google.com/maps/place/2+Armenak+Armenakyan+St,+Yerevan,+Armenia/@40.1904172,44.5300907,17z/data=!3m1!4b1!4m5!3m4!1s0x406abcdbd90d7789:0x5420c1e795053079!8m2!3d40.1904131!4d44.5322794"
          aria-label="Find location"
        ><i class="icon-location-icon"></i>
          <span [innerHTML]="'navigation_address' | translate"></span></a
        >
      </div>

      <div class="social">
        <p>{{ 'footer_coolText' | translate }}</p>
        <div class="soc-icons">
          <a
            (click)="gaEvent('linkedin')"
            target="_blank"
            href="https://www.linkedin.com/company/sceon"
            aria-label="Visit our Linkedin page"
          >
            <i class="icon-linkedin-icon"></i>
          </a>
          <a
            (click)="gaEvent('fb')"
            target="_blank"
            [href]="facebookUrl | safe: domSanitizerEnum.SafeUrl"
            aria-label="Visit our Facebook page"
          >
            <i class="icon-facebook-icon"></i>
          </a>
          <a
            (click)="gaEvent('instagram')"
            target="_blank"
            href="https://www.instagram.com/sceondev/?hl=en"
            aria-label="Visit our Instagram page"
          >
            <i class="icon-instagram-icon"></i>
          </a>
          <a
            (click)="gaEvent('youtube')"
            target="_blank"
            aria-label="Visit our Youtube channel"
            href="https://www.youtube.com/@sceondev"
          >
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M40 5.33333V0H34.6667V2H5.33333V0H0V5.33333H2V34.6667H0V40H5.33333V38H34.6667V40H40V34.6667H38V5.33333H40ZM36 1.33333H38.6667V4H36V1.33333ZM1.33333 1.33333H4V4H1.33333V1.33333ZM4 38.6667H1.33333V36H4V38.6667ZM38.6667 38.6667H36V36H38.6667V38.6667ZM36.6667 34.6667H34.6667V36.6667H5.33333V34.6667H3.33333V5.33333H5.33333V3.33333H34.6667V5.33333H36.6667V34.6667Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13 8.5C8.85786 8.5 5.5 11.8579 5.5 16V24C5.5 28.1421 8.85786 31.5 13 31.5H27C31.1421 31.5 34.5 28.1421 34.5 24V16C34.5 11.8579 31.1421 8.5 27 8.5H13ZM8.5 16C8.5 13.5147 10.5147 11.5 13 11.5H27C29.4853 11.5 31.5 13.5147 31.5 16V24C31.5 26.4853 29.4853 28.5 27 28.5H13C10.5147 28.5 8.5 26.4853 8.5 24V16ZM25.6971 21.3282C26.1908 21.0691 26.5 20.5576 26.5 20C26.5 19.4425 26.1908 18.931 25.6971 18.6719L17.4471 14.3418C16.9821 14.0977 16.4234 14.1146 15.974 14.3862C15.5246 14.6579 15.25 15.1448 15.25 15.6699V24.3302C15.25 24.8553 15.5246 25.3422 15.974 25.6139C16.4234 25.8855 16.9821 25.9024 17.4471 25.6583L25.6971 21.3282ZM18.25 21.8488V18.1513L21.7724 20L18.25 21.8488Z" fill="white"/>
            </svg>
          </a>
        </div>
      </div>
    </div>

    <div class="privacy">
      <div class="privacy-left-body">
        <p>{{ 'footer_copyright' | translate }}</p>
        <p>{{ 'footer_allRights' | translate }}</p>
      </div>

      <div class="privacy-right-body">
        <a routerLink="privacy-policy" aria-label="Visit privacy police page">{{
          'footer_privacyPolicy' | translate
        }}</a>
        <a routerLink="terms-and-conditions" aria-label="Visit terms and conditions page">{{
          'footer_termsConditions' | translate
        }}</a>
        <a routerLink="cookie-policy" aria-label="Visit cookie policy page">{{
          'footer_cookiePolicy' | translate
        }}</a>
      </div>
    </div>
  </div>
</footer>
