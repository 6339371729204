import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, take } from 'rxjs';
import { LanguageService } from '@app/dictionary/language.service';
import { SeoService } from '@app/shared/services/seo-service';
import {
  LANGUAGE_STORAGE_KEY,
  LanguageEnum,
  LanguageIsoEnum,
  RouteToLanguageEnum
} from '@app/dictionary/models/dictionary.model';
import { SearchEngineService } from '@app/_api/search-engine.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamService } from '@app/_api/team.service';

@Injectable({
  providedIn: 'root'
})
export class SeoDataResolver  {
  constructor(
    private languageService: LanguageService,
    private seoService: SeoService,
    private searchEngineService: SearchEngineService,
    private translateService: TranslateService,
    private teamService: TeamService
  ) {}

  resolve(
    activatedRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<any> {
    this.teamService.getOurTeam().subscribe((data) => {
      this.seoService.numberOfEmployees = data.length;
      this.seoService.numberOfEmployeesLoaded$.next(true);
    });

    this.searchEngineService
      .checkIfSearchEngine()
      .subscribe((isSearchEngine) => {
        const locationLang =
          typeof localStorage !== 'undefined'
            ? localStorage.getItem(LANGUAGE_STORAGE_KEY)
            : null;
        const language = activatedRoute.params['language'];

        if (RouteToLanguageEnum[language]) {
          if (typeof localStorage !== 'undefined') {
            localStorage.setItem(
              LANGUAGE_STORAGE_KEY,
              RouteToLanguageEnum[language]
            );
          }
          this.translateService.use(RouteToLanguageEnum[language]);
        } else if (LanguageIsoEnum[locationLang] && !isSearchEngine) {
          this.translateService.use(locationLang);
        } else {
          this.translateService.use(LanguageEnum.English);
          if (typeof localStorage !== 'undefined') {
            localStorage.setItem(LANGUAGE_STORAGE_KEY, LanguageEnum.English);
          }
        }
      });

    return forkJoin([
      this.seoService.numberOfEmployeesLoaded$.pipe(take(1)),
      this.languageService.isLanguageLoaded$.pipe(take(1))
    ]);
  }
}
