import { Routes } from '@angular/router';

import { CoreComponent } from './core/core.component';
import { LanguageGuard } from '@app/shared/guards/language.guard';
import { DefaultLanguageGuard } from '@app/shared/guards/default-language.guard';
import { SeoDataResolver } from '@app/core/seo-data.resolver';

const childRoutes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home-routing.module').then(
        (exports) => exports.HomeRoutingModule
      )
  },
  {
    path: 'what-we-do',
    loadChildren: () =>
      import('./what-we-do/what-we-do-routing.module').then(
        (exports) => exports.WhatWeDoRoutingModule
      )
  },
  {
    path: 'who-we-are',
    loadChildren: () =>
      import('./who-we-are/who-we-are-routing.module').then(
        (exports) => exports.WhoWeAreRoutingModule
      )
  },
  {
    path: 'join-us',
    loadChildren: () =>
      import('./join-us/join-us-routing.module').then(
        (exports) => exports.routes
      )
  },
  // {
  //   path: 'blog',
  //   loadChildren: () =>
  //     import('./blog/blog-routing.module').then((exports) => exports.BlogRoutingModule)
  // },
  {
    path: 'team',
    loadChildren: () =>
      import('./team/team-routing.module').then(
        (exports) => exports.TeamRoutingModule
      )
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./contact-us/contact-us-routing.module').then(
        (exports) => exports.ContactUsRoutingModule
      )
  },
  {
    path: 'cookie-policy',
    loadComponent: () =>
      import('./cookies-policy/cookies-policy.component').then(
        (exports) => exports.CookiesPolicyComponent
      )
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./privacy-policy/privacy-policy.component').then(
        (exports) => exports.PrivacyPolicyComponent
      )
  },
  {
    path: 'terms-and-conditions',
    loadComponent: () =>
      import('./terms-and-conditions/terms-and-conditions.component').then(
        (exports) => exports.TermsAndConditionsComponent
      )
  },
  {
    path: '404',
    loadChildren: () =>
      import('./not-found/not-found-routing.module').then(
        (exports) => exports.NotFoundRoutingModule
      )
  }
];

export const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    children: childRoutes,
    canActivate: [DefaultLanguageGuard],
    resolve: { seoData: SeoDataResolver }
  },
  {
    path: ':language',
    component: CoreComponent,
    children: childRoutes,
    canActivate: [LanguageGuard],
    resolve: { seoData: SeoDataResolver }
  },
  { path: '**', redirectTo: '/404', resolve: { seoData: SeoDataResolver } }
];
