import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { DomSanitizerEnum } from '@app/shared/models/dom-sanitizer.model';
import { SanitizePipe } from '@app/shared/pipe/safe.pipe';
import { generateFacebookUrl } from '@app/shared/utiles/social-media';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GtagService } from '@app/shared/services/gtag.service';

@Component({
  standalone: true,
  imports: [RouterModule, SanitizePipe, TranslateModule, CommonModule],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  facebookUrl: string;
  domSanitizerEnum = DomSanitizerEnum;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public translateService: TranslateService,
    private gtagService: GtagService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.facebookUrl = generateFacebookUrl();
    }
  }

  gaEvent(eventName: string) {
    this.gtagService.gtag(eventName);
  }
}
