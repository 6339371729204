import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';
import {
  DictionaryModel,
  LblCategoryEnum
} from '@app/dictionary/models/dictionary.model';
import { DictionaryService } from '@app/_api/dictionary.service';
import { LanguageService } from '@app/dictionary/language.service';

@Injectable({ providedIn: 'root' })
export class TranslateServerLoader implements TranslateLoader {
  private dictionaries: { [lang: string]: Observable<any> } = {};

  constructor(
    private dictionaryService: DictionaryService,
    private languageService: LanguageService
  ) {}

  public getTranslation(lang: string): Observable<any> {
    // Check if the dictionary for the requested language is already loaded or being loaded
    if (!this.dictionaries[lang]) {
      // If not, load the dictionary and cache the observable
      this.dictionaries[lang] = this.dictionaryService.getDictionaries().pipe(
        map((data: DictionaryModel[]) => {
          this.languageService.isLanguageLoaded$.next(true);
          const message = data.filter(
            (item: DictionaryModel) =>
              item.lblCategory === LblCategoryEnum.Exterior
          );

          const dictionary: { [key: string]: string } = {};
          message.forEach((item) => {
            dictionary[item.lblKey] = item[`value_${lang}`];
          });

          return dictionary;
        }),
        shareReplay(1) // Cache the last emitted value and share among all subscribers
      );
    }

    return this.dictionaries[lang];
  }
}

export function translateServerLoaderFactory(
  dictionaryService: DictionaryService,
  languageService: LanguageService
) {
  return new TranslateServerLoader(dictionaryService, languageService);
}
