export class DictionaryModel {
  id: number;
  lblKey: string;
  lblCategory: LblCategoryEnum;
  lblCategoryValue: string;
  value_am: string;
  value_ru: string;
  value_en: string;
  createdBy: string;
  updatedBy: number | null;
  createdAt: Date;
  updatedAt: Date;

  static fromJson(json: any): DictionaryModel {
    return {
      id: json.id,
      lblKey: json.lbl_key,
      lblCategory: json.lbl_category,
      lblCategoryValue: json.lbl_category_value,
      value_am: json.value_am,
      value_ru: json.value_ru,
      value_en: json.value_en,
      createdBy: json.created_by,
      updatedBy: json.updated_by,
      createdAt: json.created_at,
      updatedAt: json.updated_at
    };
  }
}

export enum LblCategoryEnum {
  Exterior = 1,
  Cellar
}

export enum LanguageEnum {
  Armenian = 'am',
  English = 'en'
}

export enum LanguageIsoEnum {
  en = 1,
  am
}

export enum RouteToLanguageEnum {
  hy = 'am'
}

export enum LanguageToRouteEnum {
  am = 'hy',
  en = 'en'
}

export const LANGUAGE_STORAGE_KEY = 'lng';
