<div
  class="lang-{{ translate.currentLang }}"
>
  <router-outlet></router-outlet>
  @if (languageService.isLanguageLoaded$ | async) {
    <app-cookie-pop-up [isCookieHidden]="isCookieHidden()" (cookieSeen)="isCookieHidden.set(true)"></app-cookie-pop-up>
  }
</div>

<button
  id="scroll-to-top"
  aria-label="scroll-to-top"
  [ngClass]="{ 'show-scroll': showScroll, 'mobile-scroll-up': !isCookieHidden() }"
  (click)="scrollToTop()"
  class="scroll-to-top"
>
  <i class="icon-arrow-up-outline"></i>
</button>
