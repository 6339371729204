export class TeamHashTagFilterModel {
  id: number;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  updatedBy: number;
  countTeam: number;
  team: Team[];

  static fromJSON(data: any): TeamHashTagFilterModel {
    return {
      id: data.id,
      name: data.name,
      slug: data.slug,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      createdBy: data.created_by,
      updatedBy: data.updated_by,
      countTeam: data.count_team,
      team: data.team.map((item) => ({
        id: item.id,
        name_am: item.name_am,
        name_ru: item.name_ru,
        name_en: item.name_en,
        surname_am: item.surname_am,
        surname_ru: item.surname_ru,
        surname_en: item.surname_en,
        email: item.email,
        isPublic: item.is_public,
        positionId: item.position_id,
        image: item.image,
        slug: item.slug,
        createdAt: item.created_at,
        updatedAt: item.updated_at,
        pivot: {
          jobPostHashTagId: item.job_post_hash_tag_id,
          jobPostId: item.job_post_id
        }
      }))
    };
  }
}

export interface Team {
  id: number;
  name_am: string;
  name_ru: string;
  name_en: string;
  surname_am: string;
  surname_ru: string;
  surname_en: string;
  email: string;
  isPublic: number;
  positionId: number;
  image?: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  pivot: Pivot;
}

export interface Pivot {
  hashTagId: number;
  teamId: number;
}
