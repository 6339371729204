<header xmlns="http://www.w3.org/1999/html" class="header-lng-{{translate.currentLang}}">
  <div class="changeable-logo">
    <button class="logo-btn" (click)="$event.preventDefault(); navigateHome()">
      <img src="assets/images/sceon-logo.svg" alt="sceon-logo" />
    </button>
  </div>

  <div class="hamburger" (click)="toggleMenu()">
    <div [class.open]="openMenu" class="menu btn3" data-menu="3">
      <div class="icon"></div>
    </div>
  </div>

  <menu #menuElement [class.open]="openMenu">
    <div class="lang">
      <button class="lang-btn" (click)="changeLang()">
        <img
          src="assets/images/language-icons/{{
            translate.currentLang
          }}.svg"
          alt="lang"
          />
      </button>

      <h2>{{ 'header_hello' | translate }}</h2>
    </div>

    <div class="menu-flex">
      <div class="navigation">
        <ul>
          <li>
            <a
              (click)="gaEvent('home'); hideMenu()"
              [routerLink]="['/']"
              [routerLinkActiveOptions]="{
                exact: router.url.split('?')[0] !== '/'
              }"
              routerLinkActive="active"
              aria-label="Visit home page"
              >{{ 'navigation_home' | translate }}</a
              >
            </li>
            <li>
              <a
                (click)="gaEvent('what-we-do'); hideMenu()"
                [routerLink]="['what-we-do']"
                routerLinkActive="active"
                aria-label="Visit what we do page"
                >{{ 'navigation_whatWeDo' | translate }}</a
                >
                <ul class="nested">
                  <li>
                    <a
                      [routerLink]="['what-we-do/agile-project-management']"
                      [queryParams]="queryWhatWeDoParams"
                      routerLinkActive="active"
                      aria-label="Visit agile project management page"
                      (click)="hideMenu()"
                      >{{ 'navigation_agileProjectManagement' | translate }}</a
                      >
                    </li>
                    <li>
                      <a
                        [routerLink]="['what-we-do/end-2-end-solutions']"
                        [queryParams]="queryWhatWeDoParams"
                        routerLinkActive="active"
                        aria-label="Visit end2end solutions page"
                        (click)="hideMenu()"
                        >{{ 'navigation_end2EndSolutions' | translate }}</a
                        >
                      </li>
                      <li>
                        <a
                          [routerLink]="['what-we-do/technology-stack']"
                          [queryParams]="queryWhatWeDoParams"
                          routerLinkActive="active"
                          aria-label="Visit technology stack page"
                          (click)="hideMenu()"
                          >{{ 'navigation_technologyStack' | translate }}</a
                          >
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        (click)="gaEvent('who-we-are'); hideMenu()"
                        [routerLink]="['who-we-are']"
                        routerLinkActive="active"
                        aria-label="Visit who we are page"
                        >{{ 'navigation_whoWeAre' | translate }}</a
                        >
                      </li>
                      <li>
                        <a
                          (click)="gaEvent('team'); hideMenu()"
                          [routerLink]="['team']"
                          routerLinkActive="active"
                          aria-label="Visit team page"
                          >{{ 'navigation_team' | translate }}</a
                          >
                        </li>
                        <li>
                          <a
                            (click)="gaEvent('join-us'); hideMenu()"
                            [routerLink]="['join-us']"
                            routerLinkActive="active"
                            aria-label="Visit join us page"
                            >{{ 'navigation_joinUs' | translate }}</a
                            >
                          </li>
                          <!--          <li>-->
                          <!--            <a-->
                          <!--              (click)="gaEvent('blog'); hideMenu()"-->
                          <!--              [routerLink]="['blog']"-->
                          <!--              routerLinkActive="active"-->
                          <!--              aria-label="Visit blog page"-->
                        <!--              >{{ 'navigation_blog' | translate }}</a-->
                        <!--            >-->
                      <!--          </li>-->
                      <li>
                        <a
                          (click)="gaEvent('contact-us'); hideMenu()"
                          [routerLink]="['contact-us']"
                          routerLinkActive="active"
                          aria-label="Visit contact us page"
                          >{{ 'navigation_contactUs' | translate }}</a
                          >
                        </li>
                      </ul>
                    </div>

                    <div class="info">
                      @if (hotVacanciesList.length) {
                        <div class="vacancies">
                          <svg
                            class="dashed-svg"
                            width="100%"
                            height="7"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <defs>
                              <pattern
                                id="roundedRectDash"
                                patternUnits="userSpaceOnUse"
                                width="35"
                                height="5"
                                >
                                <rect width="23" height="5" fill="white" rx="2.5" ry="2.5" />
                              </pattern>
                            </defs>
                            <rect fill="url(#roundedRectDash)" width="100%" height="5" />
                          </svg>
                          <div class="anchor-icon">
                            <a
                              class="yellow-fill-link pointer"
                              [routerLink]="['join-us']"
                              (click)="gaEvent('join-us'); hideMenu()"
                              aria-label="Visit hot vacancies list page"
                              >{{ 'header_hot_vacancies' | translate }}</a
                              >
                              <i class="icon-hot-icon"></i>
                            </div>
                            @for (vacancy of hotVacanciesList; track vacancy) {
                              <a
                                class="fs-20 mb-30 pointer link-item block white-space"
                                [routerLink]="['join-us/vacancy/', vacancy.id]"
                                aria-label="Visit vacancy page"
                                (click)="hideMenu()"
                                ><span class="text-bold-golos">{{
                                vacancy.title['title_' + translate.currentLang]
                              }}</span>
                              | {{ vacancy.experienceLevel['title_' + translate.currentLang] }}</a
                              >
                            }
                          </div>
                        }

                        @if (lastBlogData) {
                          <div class="last-article d-none-sm">
                            <p class="yellow-fill-link mb-40">Last article</p>
                            <a
                              class="fs-20 text-bold-golos pointer link-item block"
                              aria-label="Visit article page"
                              (click)="hideMenu()"
                              >{{ lastBlogData?.['title_' + translate.currentLang] }}</a
                              >
                            </div>
                          }

                          <div class="d-none-sm">
                            @if (!lastBlogData && !hotVacanciesList.length) {
                              <img
                                class="hand-img"
                                src="assets/images/header/hand.svg"
                                alt="hand.svg"
                                />
                            }
                          </div>

                          <div class="social">
                            <div class="left">
                              <a
                                class="link-item"
                                (click)="gaEvent('email')"
                                href="mailto:{{ ('navigation_email' | translate)?.trim() }}"
                                aria-label="open emil"
                                >
                                <i class="icon-email-icon"></i>
                                <span [innerHTML]="'navigation_email' | translate"></span>
                              </a>

                              <a
                                class="link-item"
                                (click)="gaEvent('phone')"
                                href="tel:{{ 'navigation_phone' | translate }}"
                                aria-label="Telephone number"
                                >
                                <i class="icon-phone-icon"></i>
                                {{ 'navigation_phone' | translate }}
                              </a>
                              <a
                                class="link-item"
                                (click)="gaEvent('location')"
                                target="_blank"
                                href="https://www.google.com/maps/place/2+Armenak+Armenakyan+St,+Yerevan,+Armenia/@40.1904172,44.5300907,17z/data=!3m1!4b1!4m5!3m4!1s0x406abcdbd90d7789:0x5420c1e795053079!8m2!3d40.1904131!4d44.5322794"
                                aria-label="Find location"
                                >
                                <i class="icon-location-icon"></i>
                                <span class="address">{{
                                  'navigation_address' | translate
                                }}</span>
                              </a>
                            </div>

                            <div class="right">
                              <a
                                (click)="gaEvent('linkedin')"
                                target="_blank"
                                aria-label="Visit our Linkedin page"
                                href="https://www.linkedin.com/company/sceon/mycompany/"
                                >
                                <i class="icon-linkedin-icon"></i>
                              </a>
                              <a
                                (click)="gaEvent('fb')"
                                target="_blank"
                                aria-label="Visit our Facebook page"
                                [href]="facebookUrl | safe: domSanitizerEnum.SafeUrl"
                                >
                                <i class="icon-facebook-icon"></i>
                              </a>
                              <a
                                (click)="gaEvent('instagram')"
                                target="_blank"
                                aria-label="Visit our Instagram page"
                                href="https://www.instagram.com/sceondev/?hl=en"
                                >
                                <i class="icon-instagram-icon"></i>
                              </a>
                            <a
              (click)="gaEvent('youtube')"
              target="_blank"
              aria-label="Visit our Youtube channel"
              href="https://www.youtube.com/@sceondev"
              class="youtube"
            >
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M40 5.33333V0H34.6667V2H5.33333V0H0V5.33333H2V34.6667H0V40H5.33333V38H34.6667V40H40V34.6667H38V5.33333H40ZM36 1.33333H38.6667V4H36V1.33333ZM1.33333 1.33333H4V4H1.33333V1.33333ZM4 38.6667H1.33333V36H4V38.6667ZM38.6667 38.6667H36V36H38.6667V38.6667ZM36.6667 34.6667H34.6667V36.6667H5.33333V34.6667H3.33333V5.33333H5.33333V3.33333H34.6667V5.33333H36.6667V34.6667Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 8.5C8.85786 8.5 5.5 11.8579 5.5 16V24C5.5 28.1421 8.85786 31.5 13 31.5H27C31.1421 31.5 34.5 28.1421 34.5 24V16C34.5 11.8579 31.1421 8.5 27 8.5H13ZM8.5 16C8.5 13.5147 10.5147 11.5 13 11.5H27C29.4853 11.5 31.5 13.5147 31.5 16V24C31.5 26.4853 29.4853 28.5 27 28.5H13C10.5147 28.5 8.5 26.4853 8.5 24V16ZM25.6971 21.3282C26.1908 21.0691 26.5 20.5576 26.5 20C26.5 19.4425 26.1908 18.931 25.6971 18.6719L17.4471 14.3418C16.9821 14.0977 16.4234 14.1146 15.974 14.3862C15.5246 14.6579 15.25 15.1448 15.25 15.6699V24.3302C15.25 24.8553 15.5246 25.3422 15.974 25.6139C16.4234 25.8855 16.9821 25.9024 17.4471 25.6583L25.6971 21.3282ZM18.25 21.8488V18.1513L21.7724 20L18.25 21.8488Z" fill="white"/>
              </svg>

            </a>
          </div>
                          </div>
                        </div>
                      </div>
                    </menu>
                  </header>
