import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchEngineService {
  constructor(private http: HttpClient) {}

  checkIfSearchEngine(): Observable<boolean> {
    return this.http
      .get<boolean>('/is-search-engine')
      .pipe(catchError(() => of(false)));
  }
}
