export const isMobileDevice = () => {
  if (typeof window !== 'undefined') {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
      userAgent
    );
  }

  return false;
};

export const setRealVh = () => {
  if (typeof window !== 'undefined') {
    let vh = window.innerHeight * 0.01;
    let vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--real-vh', `${vh}px`);
    document.documentElement.style.setProperty('--real-vw', `${vw}px`);
  }
};
