import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  inject,
  Inject,
  OnInit,
  PLATFORM_ID,
  signal
} from '@angular/core';
import { AsyncPipe, isPlatformBrowser, NgClass } from '@angular/common';
import { setRealVh } from '@app/shared/utiles/device';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@app/dictionary/language.service';
import { RouterOutlet } from '@angular/router';
import { CookiePopUpComponent } from '@app/shared/components/cookie-pop-up/cookie-pop-up.component';
import { ScrollService } from '@app/shared/services/scroll.service';
import { COOKIE_KEY } from '@app/shared/models/local-storage.model';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet, CookiePopUpComponent, NgClass, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'sceon';
  showScroll: boolean = false;
  showScrollHeight = 300;
  hideScrollHeight = 10;
  scrollService = inject(ScrollService);
  isCookieHidden = signal(true);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.isCookieHidden.set(localStorage.getItem(COOKIE_KEY) === 'true');
    }
    this.scrollService.setDarkMode();
  }

  ngAfterViewInit() {
    this.calcRealVh();
  }

  @HostListener('window:resize')
  calcRealVh() {
    if (isPlatformBrowser(this.platformId)) {
      setRealVh();
    }
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) > this.showScrollHeight
    ) {
      this.showScroll = true;
    } else if (
      this.showScroll &&
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop) < this.hideScrollHeight
    ) {
      this.showScroll = false;
    }
  }

  scrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
